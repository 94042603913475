import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import imageCompression from 'browser-image-compression';
import FileInput from 'components/@home/@messages/ChatPanel/SendForm/controls/AttachFile/FileInput';
import DrawerProvider from 'components/common/Drawer/DrawerProvider';
import Loading from 'components/common/Loading';
import Autosuggest from 'components/controls/Autosuggest';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Attachments from 'services/api/Attachments';
import Auth from 'services/api/Auth';
import Message from 'services/api/Message';
import { OMMNIO_BOT_APP_ID } from 'services/constants';
import appsActions from 'store/app/entities/apps/action';
import ommnioBotDrawer from 'store/app/ui/drawers/ommnioBotApp/action';
import authCompanyId from 'store/selectors/authCompanyId';
import uniqid from 'uniqid';
import parseFileName from 'utils/getFileExtension';
import { I18n } from 'utils/i18n';
import languages, { nativeLanguages } from 'utils/languages';
import useActions from 'utils/useActions';

const handleCheckbox =
  ({ onChange, value = [], name }) =>
  e => {
    const { checked } = e.target;
    const checkboxValue = e.target.value;
    const ret = value.filter(v => checkboxValue !== v);
    onChange({ target: { value: checked ? [...ret, checkboxValue] : ret, name } });
  };

const Option = ({ innerProps, isSelected, isDisabled, children }) => {
  return (
    <MenuItem selected={isSelected} disabled={isDisabled} {...innerProps}>
      <Checkbox checked={isSelected} />
      {children}
    </MenuItem>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.object.isRequired,
};

const OmmnioBotDrawer = () => {
  const isFetching = false;
  const [companies, setCompanies] = useState();
  const [activeTab, setActiveTab] = useState('en');
  const availableLanguages = Object.keys(languages);
  const companyId = useSelector(authCompanyId);

  // File upload state
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);
  const cancelRef = useRef(() => {});
  const filesRef = useRef([]);

  const dispatch = useDispatch();

  const app = useSelector(state => state.apps.apps?.[OMMNIO_BOT_APP_ID]);

  const visible = useSelector(state => state.drawers.ommnioBot.isOpen);

  const methods = useForm({
    defaultValues: {
      roles: ['admin', 'teamAdmin', 'member'],
      companies: [],
      languages: availableLanguages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
    },
  });

  useEffect(() => {
    if (visible && !app?.botToken) {
      dispatch(appsActions.find(OMMNIO_BOT_APP_ID));
    } else if (visible && app?.botToken) {
      Auth.user({ token: { hash: app.botToken } }).then(ret => {
        setCompanies(ret.companies);
      });
    }
  }, [app?.botToken, dispatch, visible]);

  const hide = useActions(ommnioBotDrawer.close);

  // File upload handlers
  const onUploadProgress =
    idx =>
    ({ total, loaded }) => {
      setUploadProgress(p => ({ ...p, [idx]: loaded / total }));
    };

  const getCancelFunc = onCancel => {
    cancelRef.current = onCancel;
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
  }

  const uploadFile = useCallback(
    async (file, idx) => {
      const { type } = file;
      let compressedFile;
      if (type.startsWith('image/') && type !== 'image/gif') {
        const { name } = parseFileName(file.name);
        const newFileName = `${name}.jpg`;

        // Read the file and compress it
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = async event => {
            try {
              const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1600,
                useWebWorker: true,
                fileType: 'image/jpeg',
              };

              const blob = dataURItoBlob(event.target.result);
              const imageFile = new File([blob], newFileName, {
                type: 'image/jpeg',
              });
              compressedFile = await imageCompression(imageFile, options);

              // Upload to null channel since it's going to be sent via the bot
              const attachment = await Attachments.upload(
                companyId,
                'none', // null channel
                compressedFile || file,
                'en', // default locale
                onUploadProgress(idx),
                getCancelFunc,
              );
              resolve(attachment);
            } catch (err) {
              reject(err);
            }
          };
          reader.onerror = () => reject(new Error('Error reading file'));
          reader.readAsDataURL(file);
        });
      }
      // For non-image files
      const attachment = await Attachments.upload(
        companyId,
        'none', // null channel
        file,
        'en', // default locale
        onUploadProgress(idx),
        getCancelFunc,
      );
      return attachment;
    },
    [companyId],
  );

  const handleFileChange = event => {
    const newFiles = Array.from(event.target.files).map(file => ({
      file,
      id: uniqid(),
      preview: URL.createObjectURL(file),
    }));

    setFiles(prevFiles => [...prevFiles, ...newFiles]);
    // Reset input value to allow selecting the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeFile = id => {
    setFiles(prevFiles => prevFiles.filter(f => f.id !== id));
  };

  const handleUploadError = error => {
    let msg = I18n.t('AttachFile.Errors.Unknown');
    if (error.data?.type === 'ValidationError') {
      msg = error.data.message;
    } else if (error.message === 'Canceled') {
      msg = I18n.t('AttachFile.Errors.Canceled');
    }
    setIsUploading(false);
    setErrorMessage(msg);
    setTimeout(() => setErrorMessage(''), 5000);
  };

  const handleSubmit = useCallback(
    async data => {
      try {
        if (!app?.botToken) {
          console.error('App data not available');
          setErrorMessage(I18n.t('OmmnioBot.Errors.AppNotAvailable') || 'App data not available');
          setTimeout(() => setErrorMessage(''), 5000);
          return;
        }

        // If there are files to upload, upload them first
        let attachments = [];

        // Using the ref to access the current files
        const currentFiles = filesRef.current;

        if (currentFiles.length > 0) {
          setIsUploading(true);
          // Upload all files
          const uploadedAttachments = await Promise.all(
            currentFiles.map((file, idx) => uploadFile(file.file, idx)),
          );
          attachments = uploadedAttachments.map(a => a._id);
          setIsUploading(false);
        }

        // Send the message with attachments
        await Message.broadcast(
          {
            ...data,
            companies: data.companies?.map(c => c._id),
            attachments,
          },
          { token: { hash: app?.botToken } },
        ).then(() => {
          hide();
          // Reset file state
          setFiles([]);
        });
      } catch (error) {
        handleUploadError(error);
      }
    },
    [app, hide, uploadFile, setFiles, setIsUploading, setErrorMessage],
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Calculate overall progress
  const progressArr = Object.values(uploadProgress);
  const progress = progressArr.length
    ? (progressArr.reduce((acc, curr) => acc + curr, 0) / progressArr.length) * 100
    : 0;

  useEffect(() => {
    if (isUploading) {
      console.log('Upload progress:', progress);
    }
  }, [progress, isUploading]);

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  return (
    <DrawerProvider
      name="OmmnioBotDrawer"
      visible={visible}
      onClose={hide}
      title={I18n.t('OmmnioBot.title')}
      onSubmit={methods.handleSubmit(handleSubmit)}
      sx={{ width: 1000, maxWidth: '90vw' }}
      drawerContent={
        <FormProvider {...methods}>
          {isFetching ? (
            <Loading />
          ) : (
            <Stack spacing={4}>
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    textTransform: 'uppercase',
                    letterSpacing: 0.5,
                    mb: 2,
                    display: 'block',
                  }}
                >
                  {I18n.t('OmmnioBot.targetSection')}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ width: 180, pt: 1 }}>
                        {I18n.t('OmmnioBot.companies')}
                      </Typography>
                      <Box sx={{ flexGrow: 1 }}>
                        <Controller
                          methods={methods}
                          name="companies"
                          render={({ field: { value, onChange, ...rest } }) => (
                            <Autosuggest
                              components={{ Option }}
                              isOptionSelected={(option, selectValue) =>
                                selectValue.some(v => v.value._id === option.value._id)
                              }
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              suggestions={companies?.map(c => ({ value: c, label: c.name }))}
                              onChange={values =>
                                onChange({
                                  target: {
                                    value: values.map(({ value: v }) => v),
                                    name: rest.name,
                                  },
                                })
                              }
                              value={value}
                              isMulti
                              isClearable
                              placeholder={I18n.t('OmmnioBot.selectCompanies')}
                              {...rest}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ width: 180, pt: 1 }}>
                        {I18n.t('OmmnioBot.targetRoles')}
                      </Typography>
                      <Box sx={{ flexGrow: 1 }}>
                        <Controller
                          name="roles"
                          methods={methods}
                          render={({ field: { onChange, value, ...rest } }) => (
                            <Stack direction="column" spacing={1}>
                              {['admin', 'teamAdmin', 'member'].map(role => (
                                <FormControlLabel
                                  key={role}
                                  control={
                                    <Checkbox
                                      checked={value?.includes(role) || false}
                                      onChange={handleCheckbox({ onChange, value, ...rest })}
                                      value={role}
                                      {...rest}
                                    />
                                  }
                                  label={I18n.t(`OmmnioBot.roles.${role}`)}
                                />
                              ))}
                            </Stack>
                          )}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    textTransform: 'uppercase',
                    letterSpacing: 0.5,
                    mb: 2,
                    display: 'block',
                  }}
                >
                  {I18n.t('OmmnioBot.message')}
                </Typography>

                <Paper variant="outlined">
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      mb: 0,
                      borderBottom: 1,
                      borderColor: 'divider',
                      '& .MuiTabs-indicator': {
                        height: 3,
                      },
                    }}
                  >
                    {availableLanguages.map(lang => (
                      <Tab
                        key={lang}
                        label={nativeLanguages[lang]}
                        value={lang}
                        sx={{
                          textTransform: 'none',
                          fontWeight: activeTab === lang ? 'medium' : 'normal',
                          minHeight: 40,
                        }}
                      />
                    ))}
                  </Tabs>

                  <Box sx={{ p: 2 }}>
                    {availableLanguages.map(lang => (
                      <Box key={lang} sx={{ display: activeTab === lang ? 'block' : 'none' }}>
                        <Controller
                          name={`languages.${lang}`}
                          methods={methods}
                          render={({ field: { onChange, value, ...rest } }) => {
                            return (
                              <TextField
                                fullWidth
                                multiline
                                minRows={6}
                                onChange={onChange}
                                value={value}
                                placeholder={nativeLanguages[lang]}
                                variant="outlined"
                                sx={{ backgroundColor: 'background.paper' }}
                                {...rest}
                              />
                            );
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </Box>

              {/* Attachments Section */}
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    textTransform: 'uppercase',
                    letterSpacing: 0.5,
                    mb: 2,
                    display: 'block',
                  }}
                >
                  {I18n.t('OmmnioBot.attachments')}
                </Typography>

                <FileInput onChange={handleFileChange} inputRef={fileInputRef} />

                {/* File previews */}
                {files.length > 0 ? (
                  <Stack spacing={2}>
                    {files.map((file, index) => (
                      <Box
                        key={file.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 1,
                        }}
                      >
                        {file.file.type.startsWith('image/') ? (
                          <Box
                            component="img"
                            src={file.preview}
                            sx={{
                              width: 80,
                              height: 80,
                              objectFit: 'cover',
                              borderRadius: 1,
                              mr: 2,
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              width: 80,
                              height: 80,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: 'action.hover',
                              borderRadius: 1,
                              mr: 2,
                            }}
                          >
                            <AttachFileIcon fontSize="large" />
                          </Box>
                        )}

                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" noWrap sx={{ maxWidth: '100%' }}>
                            {file.file.name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {(file.file.size / 1024).toFixed(1)} KB
                          </Typography>
                        </Box>

                        <Box>
                          {isUploading ? (
                            <CircularProgress
                              size={24}
                              variant="determinate"
                              value={uploadProgress[index] ? uploadProgress[index] * 100 : 0}
                            />
                          ) : (
                            <Tooltip title={I18n.t('OmmnioBot.removeFile')}>
                              <IconButton
                                size="small"
                                onClick={() => removeFile(file.id)}
                                color="error"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  <Box sx={{ mb: 2 }}>
                    <Button
                      variant="outlined"
                      startIcon={<AttachFileIcon />}
                      onClick={() => fileInputRef.current?.click()}
                      disabled={isUploading}
                    >
                      {I18n.t('OmmnioBot.attachFile')}
                    </Button>
                  </Box>
                )}

                {errorMessage && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    {errorMessage}
                  </Typography>
                )}
              </Box>
            </Stack>
          )}
        </FormProvider>
      }
    />
  );
};

export default OmmnioBotDrawer;
