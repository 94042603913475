/**
 * @flow
 */
import DrawerContent from 'components/common/Drawer/DrawerContent';
import { createContext } from 'components/common/Drawer/DrawerContext';
import React, { Node, useCallback, useEffect, useMemo, useRef, useState } from 'react';

type Props = {
  children?: Node,
  state?: Object,
  visible?: boolean,
  drawerContent: Node,
  name: String,
  title?: String,
  actions?: Node,
  onClose?: Function,
  onSubmit?: Function,
  onShow?: Function,
  sx?: Object,
};

const DrawerProvider = ({
  children,
  state: stateProp,
  visible: visibleProp,
  title: titleProp,
  actions,
  drawerContent,
  name,
  onClose,
  onSubmit,
  onShow,
  sx,
}: Props) => {
  const drawerContentRef = useRef();
  const [titleLocal, setTitleLocal] = useState('');
  const [stateLocal, setStateLocal] = useState();
  const [visibleLocal, setVisibleLocal] = useState();

  const show = useCallback(
    newState => {
      if (stateProp === undefined) {
        setStateLocal(newState);
      }
      if (visibleProp === undefined) {
        setVisibleLocal(true);
      }
      if (onShow) {
        onShow(newState);
      }
    },
    [onShow, stateProp, visibleProp],
  );

  const hide = useCallback(() => {
    if (visibleProp === undefined) {
      setVisibleLocal(false);
    }
    if (onClose) {
      onClose();
    }
  }, [onClose, visibleProp]);

  useEffect(() => {
    if (drawerContentRef.current) {
      drawerContentRef.current.setOnSubmit(onSubmit);
    }
  }, [onSubmit]);

  const setOnSubmit = useCallback(newOnSubmit => {
    if (drawerContentRef.current) {
      drawerContentRef.current.setOnSubmit(newOnSubmit);
    }
  }, []);

  const visible = useMemo(
    () => (visibleProp === undefined ? visibleLocal : visibleProp),
    [visibleLocal, visibleProp],
  );
  const state = useMemo(
    () => (stateProp === undefined ? stateLocal : stateProp),
    [stateLocal, stateProp],
  );
  const title = useMemo(
    () => (titleProp === undefined ? titleLocal : titleProp),
    [titleLocal, titleProp],
  );
  const [Context, setContext] = useState();
  useEffect(() => {
    setContext(createContext(name));
  }, [name]);
  if (!Context) {
    return null;
  }
  return (
    <Context.Provider
      value={{ show, hide, visible, title, setTitle: setTitleLocal, state, setOnSubmit }}
    >
      {typeof children === 'function' ? children({ show, hide }) : children}
      {visible && (
        <DrawerContent
          ref={drawerContentRef}
          drawer={name}
          title={title}
          actions={actions}
          onSubmit={onSubmit}
          onClose={onClose}
          sx={sx}
        >
          {drawerContent}
        </DrawerContent>
      )}
    </Context.Provider>
  );
};

DrawerProvider.defaultProps = {
  title: undefined,
  state: undefined,
  visible: undefined,
  onClose: undefined,
  onShow: undefined,
  children: undefined,
  actions: undefined,
  onSubmit: undefined,
  sx: {},
};

export default DrawerProvider;
