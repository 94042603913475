const languagesArr = {
  ar: ['Àrab', 'Arabic', 'Árabe', 'Arabiera', 'Árabe'],
  bg: ['Búlgar', 'Bulgarian', 'Búlgaro', 'Bulgariera', 'Búlgaro'],
  ca: ['Català', 'Catalan', 'Catalán', 'Katalana', 'Catalão'],
  cs: ['Txec', 'Czech', 'Checo', 'Txekiera', 'Checo'],
  da: ['Danès', 'Danish', 'Danés', 'Daniera', 'Dinamarquês'],
  de: ['Alemany', 'German', 'Alemán', 'Alemaniera', 'Alemão'],
  el: ['Grec', 'Greek', 'Griego', 'Greziar', 'Grego'],
  en: ['Anglès', 'English', 'Inglés', 'Ingelesa', 'Inglês'],
  es: ['Espanyol', 'Spanish', 'Español', 'Espainiera', 'Espanhol'],
  et: ['Estonià', 'Estonian', 'Estonio', 'Estoniera', 'Estoniano'],
  eu: ['Euskera', 'Basque', 'Vasco', 'Euskara', 'Basco'],
  fi: ['Finès', 'Finnish', 'Finés', 'Finlandiera', 'Finlandês'],
  fr: ['Francès', 'French', 'Francés', 'Frantsesa', 'Francês'],
  hr: ['Croat', 'Croatian', 'Croata', 'Kroaziera', 'Croata'],
  hu: ['Hongarès', 'Hungarian', 'Húngaro', 'Hungariera', 'Húngaro'],
  is: ['Islandès', 'Icelandic', 'Islandés', 'Islandiera', 'Islandês'],
  it: ['Italià', 'Italian', 'Italiano', 'Italiera', 'Italiano'],
  ja: ['Japonès', 'Japanese', 'Japonés', 'Japoniera', 'Japonês'],
  lt: ['Lituà', 'Lithuanian', 'Lituano', 'Lituania', 'Lituano'],
  lv: ['Letó', 'Latvian', 'Letón', 'Letoniera', 'Letão'],
  mk_MK: ['Macedoni', 'Macedonian', 'Macedonio', 'Mazedoniera', 'Macedónio'],
  nl: ['Neerlandès', 'Dutch', 'Neerlandés', 'Nederlandera', 'Neerlandês'],
  no: ['Noruec', 'Norwegian', 'Noruego', 'Norvegiera', 'Norueguês'],
  pl: ['Polonès', 'Polish', 'Polaco', 'Poloniera', 'Polaco'],
  pt: ['Portuguès', 'Portuguese', 'Portugués', 'Portugesa', 'Português'],
  ro: ['Romanès', 'Romanian', 'Rumano', 'Errumaniera', 'Romeno'],
  ru: ['Rus', 'Russian', 'Ruso', 'Errusiera', 'Russo'],
  sk: ['Eslovac', 'Slovak', 'Eslovaco', 'Eslovakiera', 'Eslovaco'],
  sl: ['Eslovè', 'Slovenian', 'Esloveno', 'Esloveniera', 'Esloveno'],
  sq: ['Albanès', 'Albanian', 'Albanés', 'Albaniera', 'Albanês'],
  sr: ['Serbi', 'Serbian', 'Serbio', 'Serbiera', 'Sérvio'],
  sv: ['Suec', 'Swedish', 'Sueco', 'Suediera', 'Sueco'],
  tr: ['Turc', 'Turkish', 'Turco', 'Turkiera', 'Turco'],
  uk: ['Ucraïnès', 'Ukrainian', 'Ucraniano', 'Ukrainiera', 'Ucraniano'],
  vi: ['Vietnamita', 'Vietnamese', 'Vietnamita', 'Vietnamera', 'Vietnamita'],
  zh_CN: ['Xinès', 'Chinese', 'Chino', 'Txinera', 'Chinês'],
};

export const nativeLanguages = {
  ar: 'اَلْعَرَبِيَّةُ',
  bg: 'български език',
  ca: 'Català',
  cs: 'čeština',
  da: 'dansk',
  de: 'Deutsch',
  el: 'Ελληνικά',
  en: 'English',
  es: 'Español',
  et: 'eesti',
  eu: 'Euskara',
  fi: 'suomi',
  fr: 'Français',
  hr: 'Hrvatski',
  hu: 'magyar',
  is: 'Íslenska',
  it: 'Italiano',
  ja: '日本語',
  lt: 'lietuvių kalba',
  lv: 'latviešu valoda',
  mk_MK: 'македонски јазик',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  ru: 'Русский',
  sk: 'slovenčina',
  sl: 'slovenščina',
  sq: 'Shqip',
  sr: 'српски језик',
  sv: 'Svenska',
  tr: 'Türkçe',
  uk: 'Українська',
  vi: 'Tiếng Việt',
  zh_CN: '中文',
};

const languageIndex = {
  en: 0,
  es: 1,
  ca: 2,
  eu: 3,
  pt: 4,
};

const compare =
  lang =>
  ({ label: a }, { label: b }) => {
    return a.localeCompare(b, lang, { sensitivity: 'base' });
  };

const getLanguages = lang => {
  const idx = languageIndex[lang];
  return Object.entries(languagesArr)
    .map(([value, label]) => ({ value, label: label[idx] }))
    .sort(compare(lang));
};

const languages = Object.keys(languageIndex).reduce(
  (acc, key) => ({
    ...acc,
    [key]: getLanguages(key),
  }),
  {},
);

export default languages;
